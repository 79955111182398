import emailJs from "@emailjs/browser";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Contacts as contactData } from "../data/contact";
import { useSmoothScrollTo } from "../utility/utils";
import { Alert } from "./alert";

export const ContactUs = () => {
  const [show, setShow] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [show]);

  const form = useRef<HTMLFormElement>(null);
  const bind = useSmoothScrollTo("#Contact-us");
  const [emailJsResponse, setEmailJsResponse] = useState<Boolean | undefined>(
    undefined
  );

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    setDisabled(true);
    const isValidForm = form.current?.reportValidity();
    if (!isValidForm) {
      setDisabled(false);
      return false;
    } else {
      form?.current &&
        emailJs
          .sendForm(
            "service_ml2w6vr",
            "template_g3oynns",
            form.current,
           "oEbqZ1zSfh4SGqCyd"
          )
          .then(
            (result) => {
              console.log(result);
              form.current?.reset();
              setEmailJsResponse(true);
              setShow(true);
              setDisabled(false);
            },
            (error) => {
              console.log(error.text);
              setEmailJsResponse(false);
              setShow(true);
              setDisabled(false);
            }
          );
    }

    return false;
  };

  const hrefContact = `tel: ${contactData.phone}`;
  const hrefEmail = `mailto: ${contactData.email}`;

  return (
    <div {...bind} className="text-gray-600 body-font bg-slate-50">
      <div className="w-full">
        <h2 className="text-yellow-500 text-3xl md:text-4xl lg:text-5xl py-8 md:py-12 text-center">
          Contact Us
        </h2>
        <div className="flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              title="map"
              scrolling="no"
              src={contactData.iFrameAddress}
            ></iframe>
            <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">{contactData.address}</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a href={hrefEmail} className="text-yellow-500 leading-relaxed">
                  {contactData.email}
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>

                <a className="leading-relaxed" href={hrefContact}>
                  {contactData.phone}
                </a>
              </div>
            </div>
          </div>
          <form
            ref={form}
            className="lg:w-1/3 md:w-1/2 bg-white flex flex-col p-8 rounded md:ml-auto w-full mt-8 md:mt-0"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(e);
            }}
          >
            <h2 className="text-gray-900 text-3xl mb-1 font-medium title-font">
              {contactData.title}
            </h2>
            <p className="mb-4">{contactData.info}</p>
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-600">Name</label>
              <input
                type="text"
                id="name"
                name="from_name"
                minLength={3}
                required
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-600">Email</label>
              <input
                type="email"
                id="email"
                name="from_email"
                required
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-600">Phone</label>
              <input
                type="tel"
                id="phone"
                name="from_number"
                required
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-600">Message</label>
              <textarea
                id="message"
                name="message"
                required
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button
              disabled={isDisabled}
              className="text-white bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Submit
            </button>
            {/* <p className="text-xs text-gray-500 mt-3">
            Chicharrones blog helvetica normcore iceland tousled brook viral
            artisan.
          </p> */}
          </form>
        </div>
      </div>
      {show ? (
        emailJsResponse === undefined ? null : emailJsResponse ? (
          <Alert alertType="success" />
        ) : (
          <Alert alertType="error" />
        )
      ) : null}
    </div>
  );
};
